export default  [
  {
    name: 'ABC Shirts', 
    url: require('../../asset/image/art/1000/abcd-shirts-1000.png'),
    thumbnail: require('../../asset/image/art/50/abcd-shirts-50.png'),
    description: 'A person painting the alphabet on t-shirts.',
    isDouble: false,
  },
  {
    name: 'Adult', 
    url: require('../../asset/image/art/1000/adult-1000.png'),
    thumbnail: require('../../asset/image/art/50/adult-50.png'),
    description: '"I\'m getting my life together"',
    isDouble: false,
  },
  {
    name: 'Bad Luck', 
    url: require('../../asset/image/art/1000/bad-luck-1000.png'),
    thumbnail: require('../../asset/image/art/50/bad-luck-50.png'),
    description: 'A person surounded by bad luck.',
    isDouble: false,
  },
  {
    name: 'Big Brother', 
    url: require('../../asset/image/art/1000/big-bro-1000.png'),
    thumbnail: require('../../asset/image/art/50/big-bro-50.png'),
    description: 'Just an ordinary day with lots of survailance.',
    isDouble: false,
  },
  {
    name: 'Bus Stop', 
    url: require('../../asset/image/art/1000/bus-stop-1000.png'),
    thumbnail: require('../../asset/image/art/50/bus-stop-50.png'),
    description: 'Waiting for the bus.',
    isDouble: false,
  },
  {
    name: 'Cookout', 
    url: require('../../asset/image/art/1000/cook-out-1000.png'),
    thumbnail: require('../../asset/image/art/50/cook-out-50.png'),
    description: 'Grilling in the woods.',
    isDouble: false,
  },
  {
    name: 'Our Queen',
    url: require('../../asset/image/art/1000/forest-queen-1000.png'),
    thumbnail: require('../../asset/image/art/50/forest-queen-50.png'),
    description: 'The Mother, Our Queen',
    isDouble: false,
  },
  {
    name: 'Fortune',
    url: require('../../asset/image/art/1000/fortune-et-1000.png'),
    thumbnail: require('../../asset/image/art/50/fortune-et-50.png'),
    description: 'Observing a fortune teller at work.',
    isDouble: false,
  },
  {
    name: 'Grimlin Garden',
    url: require('../../asset/image/art/1000/grimlin-garden-1000.png'),
    thumbnail: require('../../asset/image/art/50/grimlin-garden-50.png'),
    description: 'Couple of grimlins in the garden',
    isDouble: false,
  },
  {
    name: 'Grocery Fashion',
    url: require('../../asset/image/art/1000/grocey-fashion-1000.png'),
    thumbnail: require('../../asset/image/art/50/grocey-fashion-50.png'),
    description: 'Coupole of good lookin kid in the produce section.',
    isDouble: false,
  },
  {
    name: 'Guuh',
    url: require('../../asset/image/art/1000/guuh-1000.png'),
    thumbnail: require('../../asset/image/art/50/guuh-50.png'),
    description: 'Guuh',
    isDouble: false,
  },
  {
    name: 'Hey Bud',
    url: require('../../asset/image/art/1000/hey-bud-1000.png'),
    thumbnail: require('../../asset/image/art/50/hey-bud-50.png'),
    description: 'Dog on a boat, Boy on a dock.',
    isDouble: false,
  },
  {
    name: 'Internet Cowboys',
    url: require('../../asset/image/art/1000/internet-cowboys-1000.png'),
    thumbnail: require('../../asset/image/art/50/internet-cowboys-50.png'),
    description: 'Internet cowboys be like "Don\'t forget to like and subscribe yall".',
    isDouble: false,
  },
  {
    name: 'Jello Theater',
    url: require('../../asset/image/art/1000/jello-theater-1000.png'),
    thumbnail: require('../../asset/image/art/50/jello-theater-50.png'),
    description: 'A Space Jello Movie.',
    isDouble: false,
  },
  {
    name: 'Jester',
    url: require('../../asset/image/art/1000/jester-0-1000.png'),
    thumbnail: require('../../asset/image/art/50/jester-0-50.png'),
    description: 'Just a Jester.',
    isDouble: false,
  },
  {
    name: 'Jester',
    url: require('../../asset/image/art/1000/jester-1-1000.png'),
    thumbnail: require('../../asset/image/art/50/jester-1-50.png'),
    description: 'Just a Jester.',
    isDouble: false,
  },
  {
    name: 'Laundry',
    url: require('../../asset/image/art/1000/laundry-1000.png'), 
    thumbnail: require('../../asset/image/art/50/laundry-50.png'), 
    description: 'Doing laundry in a laundromatt.',
    isDouble: false,
  },
  {
    name: 'Strolling through the neighborhood',
    url: require('../../asset/image/art/1000/mail-stroll-1000.png'), 
    thumbnail: require('../../asset/image/art/50/mail-stroll-50.png'), 
    description: 'A girl strolling through the neighborhood.',
    isDouble: false,
  },
  {
    name: 'Moral Support',
    url: require('../../asset/image/art/1000/moral-support-1000.png'), 
    thumbnail: require('../../asset/image/art/50/moral-support-50.png'), 
    description: 'A friend "being there" for a friend.',
    isDouble: false,
  },
  {
    name: 'Mountain Lunch',
    url: require('../../asset/image/art/1000/mountain-lunch-1000.png'), 
    thumbnail: require('../../asset/image/art/50/mountain-lunch-50.png'), 
    description: 'Lunch in the mountains with a cat.',
    isDouble: false,
  },
  {
    name: 'Night Stroll',
    url: require('../../asset/image/art/1000/night-stroll-1000.png'), 
    thumbnail: require('../../asset/image/art/50/night-stroll-50.png'), 
    description: 'Wonderin in the dark with a flashlight',
    isDouble: false,
  },
  {
    name: 'Photo Shoot',
    url: require('../../asset/image/art/1000/photo-shoot-1000.png'), 
    thumbnail: require('../../asset/image/art/50/photo-shoot-50.png'), 
    description: 'A photo shoot.',
    isDouble: false,
  },
  {
    name: 'Pizza Maker',
    url: require('../../asset/image/art/1000/pizza-1000.png'), 
    thumbnail: require('../../asset/image/art/50/pizza-50.png'), 
    description: 'Making a Pizza.',
    isDouble: false,
  },
  {
    name: 'Say Cheese',
    url: require('../../asset/image/art/1000/say-cheese-1000.png'), 
    thumbnail: require('../../asset/image/art/50/say-cheese-50.png'), 
    description: 'Saping a candid photo in the mountians.',
    isDouble: false,
  },
  {
    name: 'See yall later',
    url: require('../../asset/image/art/1000/seeyalater-1000.png'), 
    thumbnail: require('../../asset/image/art/50/seeyalater-50.png'), 
    description: 'They are running away.',
    isDouble: false,
  },
  {
    name: 'Sound Smith',
    url: require('../../asset/image/art/1000/sound-smith-1000.png'), 
    thumbnail: require('../../asset/image/art/50/sound-smith-50.png'), 
    description: 'A sound-smith doing what a sound-smith do.',
    isDouble: false,
  },
  {
    name: 'Sunday',
    url: require('../../asset/image/art/1000/sunday-1000.png'), 
    thumbnail: require('../../asset/image/art/50/sunday-50.png'), 
    description: 'Sunday chorz.',
    isDouble: false,
  },
  {
    name: 'Tennis',
    url: require('../../asset/image/art/1000/tennis-1000.png'), 
    thumbnail: require('../../asset/image/art/50/tennis-50.png'), 
    description: 'Slamming a tennis ball.',
    isDouble: false,
  },
  {
    name: 'We love cat',
    url: require('../../asset/image/art/1000/we-love-cat-1000.png'), 
    thumbnail: require('../../asset/image/art/50/we-love-cat-50.png'), 
    description: 'Two people love the cat.',
    isDouble: false,
  },
]
